/* Satwik Pasumarthi (1002150664)
Sai Hruthik Sriperambudur (1002166949)
Manoj Kumar Anand (1002189754)
Yalamuri Sai Jyothi (1002125017)
Reddy Tejaswini N Peddavemannagari (1002159279) */

.incident-response {
  padding: 20px;
}

.incident-response h2 {
  text-align: center;
  margin-bottom: 25px;
}