/* General styling for the entire component */
.prescription-manager {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

/* Styling for the form within the component */
.prescription-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.prescription-form input,
.prescription-form button {
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 4px;
}

.prescription-form button {
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    border-color: #4CAF50;
}

.prescription-form button:hover {
    background-color: #45a049;
}

/* Styling for error messages */
.error-message {
    color: red;
    font-size: 16px;
    margin: 10px 0;
}

.prescriptions-list li button {
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}

.prescriptions-list li button.edit {
    background-color: #FFC107;
    border-color: #FFC107;
    color: #333;
}

.prescriptions-list li button.edit:hover {
    background-color: #e6b700;
}

.prescriptions-list li button.delete {
    background-color: #F44336;
    border-color: #F44336;
}

.prescriptions-list li button.delete:hover {
    background-color: #da190b;
}

/* Clear button styling */
.clear-button {
    background-color: #bbb;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.clear-button:hover {
    background-color: #a9a9a9;
}