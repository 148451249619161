/* Satwik Pasumarthi (1002150664)
Sai Hruthik Sriperambudur (1002166949)
Manoj Kumar Anand (1002189754)
Yalamuri Sai Jyothi (1002125017)
Reddy Tejaswini N Peddavemannagari (1002159279) */

/* StatsSection.css */
.stats-section {
    display: flex;
    justify-content: space-around;
    background-color: #ffffff; /* Adjust the color to match your design */
    padding: 20px 0;
  }
  
  .stat-item {
    text-align: center;
  }
  
  .stat-item h2 {
    color: #203a4c; /* Adjust the color to match your design */
    font-size: 2em; /* Adjust the size to match your design */
    margin-bottom: 10px;
  }
  
  .stat-item p {
    color: #5f7a86; /* Adjust the color to match your design */
    font-size: 1em; /* Adjust the size to match your design */
  }
  