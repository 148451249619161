/* Satwik Pasumarthi (1002150664)
Sai Hruthik Sriperambudur (1002166949)
Manoj Kumar Anand (1002189754)
Yalamuri Sai Jyothi (1002125017)
Reddy Tejaswini N Peddavemannagari (1002159279) */

.prescriptions-container {
    margin: auto;
    width: 80%;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #ffffff;
  }
  
  .prescriptions-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .prescriptions-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .prescriptions-table th, 
  .prescriptions-table td {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .prescriptions-table thead {
    background-color: #f5f5f5;
  }
  
  .prescriptions-table tbody tr:hover {
    background-color: #f0f0f0;
  }
  