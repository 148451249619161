/* Satwik Pasumarthi (1002150664)
Sai Hruthik Sriperambudur (1002166949)
Manoj Kumar Anand (1002189754)
Yalamuri Sai Jyothi (1002125017)
Reddy Tejaswini N Peddavemannagari (1002159279) */

.sidebar {
    height: 100vh; /* Full-height */
    width: 150px; /* Set the width of the sidebar */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #f1f1f1; /* Black */
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 20px;
  }
  
  .sidebar-nav {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .nav-item {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 15px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .nav-item:hover {
    color: #f1f1f1;
  }
  
  /* Highlight the active/current link */
  .nav-link.active {
    color: #f1f1f1;
    background-color: #575757; /* Darker grey background on active link */
  }
  
  @media screen and (max-height: 450px) {
    .sidebar {padding-top: 15px;}
    .sidebar a {font-size: 18px;}
  }
  