/* Satwik Pasumarthi (1002150664)
Sai Hruthik Sriperambudur (1002166949)
Manoj Kumar Anand (1002189754)
Yalamuri Sai Jyothi (1002125017)
Reddy Tejaswini N Peddavemannagari (1002159279) */

/* SignIn.css */
.signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .signin-box {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 350px;
  }
  
  .signin-box h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .signin-box p {
    margin-bottom: 30px;
    color: #666;
  }
  
  .signin-box .input-group {
    margin-bottom: 20px;
  }
  
  .signin-box input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .signin-box .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .signin-box .options label {
    color: #666;
  }
  
  .signin-box .options a {
    color: #6e57e0; /* Adjust color to match design */
    text-decoration: none;
  }
  
  .signin-box button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #6e57e0;
  }  