/* Satwik Pasumarthi (1002150664)
Sai Hruthik Sriperambudur (1002166949)
Manoj Kumar Anand (1002189754)
Yalamuri Sai Jyothi (1002125017)
Reddy Tejaswini N Peddavemannagari (1002159279) */


.health-care-admin {
    height: 100vh;
    background: #f0f4f8;
}

.health-care-admin .row {
    display: flex;
    height: calc(100vh - 74px);
}

.health-care-admin ul {
    width: 200px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.health-care-admin li a {
    display: block;
    color: #000;
    padding: 8px 16px;
    text-decoration: none;
}

.health-care-admin li a.active {
    background-color: rgb(20, 25, 40);
    color: white;
}

.health-care-admin li a:hover:not(.active) {
    background-color: #555;
    color: white;
}

.user-data {
    width: calc(100vw - 200px);
    padding: 12px
}

.child-nav {
    padding-left: 35px !important;
}

.health-care-admin .side-nav,
.health-care-admin .sidenav-btn {
    display: none;
}

.users-info {
    height: 100%;
    width: 100%;
    background-color: rgb(20, 25, 40);
    color: white;
    border-radius: 15px;
    overflow: auto;
}

.table-content {
    overflow-x: scroll;
}

.user-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.user-table thead {
    background-color: black;
    text-align: center;
}

.user-table th,
.user-table td {
    border: 0px solid white;
    padding: 10px;
    text-align: left;
}

.toggle-buttons {
    background-color: #0056b3 !important;
    color: #ffffff;
    width: 100px;
    margin-top: 5px;
}

.text-center {
    text-align: center !important;
}

@media screen and (max-width: 768px) {
    .health-care-admin ul {
        display: none;
    }

    .user-data {
        width: 100%;
    }

    .health-care-admin .side-nav {
        display: block;
        position: fixed;
        left: 0;
        height: 100%;
        padding: 10px 0px;
        overflow-x: hidden;
        transition: 0.5s;
        z-index: 1;
    }

    .sidenav-btn {
        display: block !important;
        position: relative;
        font-size: 40px;
        width: 30px;
        left: 20px;
        height: 40px;
    }

    .health-care-admin .close-btn {
        font-size: 18px;
        left: 80%;
        padding: 5px 15px;
        text-align: end;
    }

    .health-care-admin .child-nav {
        padding-left: 35px;
    }
}

.prescription-item {
    margin: 10px 0;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}