/* Satwik Pasumarthi (1002150664)
Sai Hruthik Sriperambudur (1002166949)
Manoj Kumar Anand (1002189754)
Yalamuri Sai Jyothi (1002125017)
Reddy Tejaswini N Peddavemannagari (1002159279) */

.communication {
  padding: 20px;
}

.communication h2 {
  text-align: center;
  margin-bottom: 25px;
}


.communication .card {
  transition: 0.3s;
  width: 95%;
  background: white;
  padding: 20px;
}

.communication .patient {
  position: relative;
  left: 50%;
  width: 50%;
}


.communication textarea {
  width: 100%;
  height: 100px;
}