/* Satwik Pasumarthi (1002150664)
Sai Hruthik Sriperambudur (1002166949)
Manoj Kumar Anand (1002189754)
Yalamuri Sai Jyothi (1002125017)
Reddy Tejaswini N Peddavemannagari (1002159279) */

/* HeroSection.css */
.hero-section {
    background-color: #f0f4f8; /* Adjust the color to match your design */
    text-align: center;
    padding: 40px 20px;
  }
  
  .hero-section h1 {
    color: #203a4c; /* Adjust the color to match your design */
    margin-bottom: 20px;
    font-size: 2em; /* Adjust the size to match your design */
  }
  
  .hero-section p {
    color: #5f7a86; /* Adjust the color to match your design */
    margin-bottom: 30px;
    font-size: 1em; /* Adjust the size to match your design */
  }
  
  .hero-section button {
    background-color: #4caf50; /* Adjust the color to match your design */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1em; /* Adjust the size to match your design */
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .hero-section button:hover {
    background-color: #45a049;
  }
  