/* Satwik Pasumarthi (1002150664)
Sai Hruthik Sriperambudur (1002166949)
Manoj Kumar Anand (1002189754)
Yalamuri Sai Jyothi (1002125017)
Reddy Tejaswini N Peddavemannagari (1002159279) */

.health-admin-dashboard {
    font-family: Arial, sans-serif;
    margin: 20px;
}

.health-admin-dashboard h1 {
    text-align: center;
    margin-bottom: 20px;
}

.user-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
}

.user-table th,
.user-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.user-table th {
    background-color: #4CAF50;
    color: white;
}

.user-table tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
}

.user-table tbody tr:hover {
    background-color: #ddd;
}

.facility-management,
.staff-cordination,
.compliance-oversight,
.incident-response {
    margin-bottom: 30px;
}

.facility-management h2,
.staff-cordination h2,
.compliance-oversight h2,
.incident-response h2 {
    color: #205072;
    border-bottom: 2px solid #4CAF50;
    padding-bottom: 5px;
}

.compliance-oversight ul {
    list-style-type: none;
    padding: 0;
}

.compliance-oversight li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.compliance-oversight li:last-child {
    border-bottom: none;
}

.compliance-oversight div {
    margin-bottom: 10px;
}

.compliance-oversight h3 {
    color: #333;
    font-size: 18px;
}

@media (max-width: 768px) {
    .user-table {
        display: block;
        overflow-x: auto;
    }

    .user-table th,
    .user-table td {
        padding: 12px 10px;
    }
}

.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 8px;
}

form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

form label {
    margin-bottom: 5px;
    font-weight: bold;
}

form input,
form textarea {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

form button:hover {
    background-color: #0056b3;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

li:last-child {
    border-bottom: none;
}

button {
    margin-left: 10px;
}

button.edit,
button.delete {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button.edit {
    background-color: #ffc107;
    color: black;
}

button.delete {
    background-color: #dc3545;
    color: white;
}

button.edit:hover {
    background-color: #e0a800;
}

button.delete:hover {
    background-color: #c82333;
}