/* Satwik Pasumarthi (1002150664)
Sai Hruthik Sriperambudur (1002166949)
Manoj Kumar Anand (1002189754)
Yalamuri Sai Jyothi (1002125017)
Reddy Tejaswini N Peddavemannagari (1002159279) */

/* Appointments.css */

.patient-details {
  background-color: #f9f9f9; /* Light grey background */
  border-radius: 8px;
  padding: 1em;
  margin-bottom: 1em; /* Add some space before the appointments header */
}

.patient-details h2 {
  color: #333;
  margin: 0.5em 0;
}

.patient-details p {
  color: #666;
  margin: 0.25em 0;
}
.patient-details {
  background-color: #f9f9f9; /* Light grey background */
  border-radius: 8px;
  padding: 1em;
  margin-bottom: 1em; /* Add some space before the appointments header */
}

.patient-details h2 {
  color: #333;
  margin: 0.5em 0;
}

.patient-details p {
  color: #666;
  margin: 0.25em 0;
}
.appointments-container {
    margin: auto;
    width: 90%;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .appointments-container h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  table thead {
    background-color: #f5f5f5;
  }
  
  table th,
  table td {
    text-align: left;
    padding: 12px 15px;
    border-bottom: 1px solid #e1e1e1;
  }
  
  /* Add more styles for filter, search input, and 'Schedule an Appointment' button */
  