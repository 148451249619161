/* Satwik Pasumarthi (1002150664)
Sai Hruthik Sriperambudur (1002166949)
Manoj Kumar Anand (1002189754)
Yalamuri Sai Jyothi (1002125017)
Reddy Tejaswini N Peddavemannagari (1002159279) */

/* ConsultationSection.css */
.consultation-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 60px;
    background-color: #e8eff5; /* Adjust the color to match your design */
  }
  
  .consultation-content h2 {
    color: #203a4c; /* Adjust the color to match your design */
    font-size: 1.5em; /* Adjust the size to match your design */
    margin-bottom: 20px;
  }
  
  .consultation-content p {
    color: #5f7a86; /* Adjust the color to match your design */
    font-size: 1em; /* Adjust the size to match your design */
  }
  
  .consultation-image {
    /* Adjust the size to fit your image */
    width: 50%;
  }
  
  .consultation-image img {
    width: 100%;
    height: auto;
  }
  