/* Satwik Pasumarthi (1002150664)
Sai Hruthik Sriperambudur (1002166949)
Manoj Kumar Anand (1002189754)
Yalamuri Sai Jyothi (1002125017)
Reddy Tejaswini N Peddavemannagari (1002159279) */

.dashboard-container {
    display: flex;
    font-family: 'Arial', sans-serif;
  }
  
  .sidebar {
    /* Styles for sidebar */
    /* width: 250px;
    background: #f5f5f5; /* Light gray background */
    /* height: 100vh; */ 
    /* ... other styles ... */
  }
  
  .main-content {
    flex-grow: 1;
    padding: 20px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* ... other styles ... */
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    /* ... other styles ... */
  }
  
  thead {
    background-color: #f4f4f4;
    /* ... other styles ... */
  }
  
  th, td {
    text-align: left;
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
    /* ... other styles ... */
  }
  
  tbody tr:hover {
    background-color: #eaeaea;
    /* ... other styles ... */
  }
  
  button {
    /* Styles for button */
  }
  
  /* ... Additional CSS for responsiveness and design ... */
  
  .patient-tab {
    margin-top: 25px;
  }