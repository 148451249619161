/* Satwik Pasumarthi (1002150664)
Sai Hruthik Sriperambudur (1002166949)
Manoj Kumar Anand (1002189754)
Yalamuri Sai Jyothi (1002125017)
Reddy Tejaswini N Peddavemannagari (1002159279) */

.compliance-oversight h2 {
  padding: 15px;
  text-align: center;
}

.compliance-oversight ul {
  display: block !important;
  width: 100% !important;
  background-color: rgb(20, 25, 40) !important;
}

.compliance-oversight li {
  margin: 10px;
}

.compliance-oversight li div {
  background: white;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
}