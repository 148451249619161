.chat-container {
    display: flex;
    height: calc(100vh - 60px);
    padding-top: 60px;
    /* Height of the header */
    box-sizing: border-box;
    width: 100%;
}

.card {
    background-color: #fff;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.contacts-card {
    width: 30%;
    height: 100%;
    overflow: auto;
    /* Ensures scrolling within the card */
}

.chat-card {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.list-reset {
    list-style: none;
    padding: 0;
    margin: 0;
}

.contact-item {
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;
}

.contact-item:hover,
.contact-item.active {
    background-color: #f9f9f9;
}

.chat-messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
}

.message {
    margin: 10px 0;
    display: flex;
}

.message.sent {
    justify-content: flex-end;
}

.message.received {
    justify-content: flex-start;
}

.message-bubble {
    max-width: 70%;
    padding: 10px;
    border-radius: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
}

.new-message-area {
    border-top: 1px solid #eee;
    padding: 10px;
    display: flex;
}

.input-field {
    flex: 1;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    outline: none;
}

.send-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 10px;
}

.send-button:hover {
    background-color: #45a049;
}

.chat-header {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    padding: 10px;
    border-bottom: 1px solid #eee;
}