/* Satwik Pasumarthi (1002150664)
Sai Hruthik Sriperambudur (1002166949)
Manoj Kumar Anand (1002189754)
Yalamuri Sai Jyothi (1002125017)
Reddy Tejaswini N Peddavemannagari (1002159279) */

.contact-us-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .contact-us-container h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .contact-details {
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .contact-details p {
    margin: 0.5rem 0;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-form textarea {
    resize: vertical; /* Allows only vertical resizing */
    height: 150px;
  }
  
  .contact-form button {
    background-color: #007bff; /* Bootstrap primary blue */
    color: white;
    padding: 1rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 1rem;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  