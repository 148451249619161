.medication-reminders-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.medication-reminders-container h1 {
    color: #2C3E50;
    text-align: center;
    margin-bottom: 20px;
}

.reminders-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.reminder-item {
    background: #ffffff;
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
}

.reminder-item span {
    margin-right: 20px;
}

.button {
    padding: 8px 16px;
    font-size: 14px;
    color: white;
    background-color: #3498db;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button.delete {
    background-color: #e74c3c;
}

.button:hover {
    opacity: 0.9;
}

.button.delete:hover {
    background-color: #c0392b;
}



/* appointment  */
li {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    list-style-type: none;
}

button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #d32f2f;
}

input {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: block;
    width: 95%;
}