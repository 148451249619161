/* Satwik Pasumarthi (1002150664)
Sai Hruthik Sriperambudur (1002166949)
Manoj Kumar Anand (1002189754)
Yalamuri Sai Jyothi (1002125017)
Reddy Tejaswini N Peddavemannagari (1002159279) */

/* src/components/Header.css */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000;
  }
  
  .navigation {
    display: flex;
    gap: 1rem;
  }
  
  .nav-link {
    text-decoration: none;
    color: #000000;
    font-weight: 500;
    padding: 0.5rem 1rem;
    transition: color 0.3s;
  }
  
  .nav-link:hover {
    color: #0056b3;
  }
  
  .auth-buttons {
    display: flex;
    gap: 0.5rem;
  }
  
  .btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login {
    background-color: #ffffff;
    color: #000000;
  }
  
  .login:hover {
    background-color: #f8f8f8;
  }
  
  .sign-up {
    background-color: #0056b3;
    color: #ffffff;
  }
  
  .sign-up:hover {
    background-color: #003d82;
  }
  